<template>
    <modal name="allocation-list" classes="work-modal" :adaptive="true" height="50%" :maxWidth="480" width="50%" @before-open="onBeforeOpen">
        <div class="flex flex-col max-h-full h-full">
            <div class="bg-primary py-2 px-4 flex items-center justify-between">
                <h3 class="text-white-text font-semibold text-lg flex items-center gap-2">{{ heading }}</h3>
                <span class="cursor-pointer text-white-text cross" @click="hideModal">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" fill="white" class="h-7 w-7">
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                    </svg>
                </span>
            </div>
            <div class="container flex-grow overflow-hidden p-4 flex flex-col max-h-full h-full">
                <div class="border border-gray-200 border-solid rounded-lg flex flex-col overflow-hidden min-h-full">
                    <div class="relative w-full p-2">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                        </div>
                        <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5" placeholder="Search" v-model="search" />
                    </div>
                    <div class="p-2 flex w-full overflow-y-auto max-h-full scroll-bar">
                        <ul class="divide-y divide-gray-200 w-full" :class="{ 'border-b border-solid border-gray-200': !isLoading }">
                            <template v-if="isLoading">
                                <li class="py-6 w-full text-center">
                                    <Loader />
                                </li>
                            </template>
                            <template v-else-if="getAllocatedResources.length > 0">
                                <li class="py-2 w-full" v-for="resource in getAllocatedResources" :key="resource.id">
                                    <div class="flex items-center">
                                        <svg v-if="type === 'users'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-primary">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                        </svg>
                                        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-primary">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                            />
                                        </svg>
                                        <span class="ml-4">{{ fetchResource(resource) }}</span>
                                        <span title="Remove" class="ml-auto text-red-500 cursor-pointer hover:opacity-80" @click="removeResource(resource)">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </span>
                                    </div>
                                </li>
                            </template>
                            <template v-else>
                                <li class="py-2 w-full">
                                    <div class="text-center">No Results!</div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
const Loader = () => import("@shared/loader");

export default {
    name: "allocation-list",
    data() {
        return {
            type: null,
            search: "",
            selectedRow: null,
        };
    },
    components: {
        Loader,
    },
    props: {
        allocatedResources: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        hideModal() {
            this.$modal.hide("allocation-list");
        },
        onBeforeOpen({ params }) {
            this.search = "";
            this.type = params.type;
            this.selectedRow = params.row;
        },
        fetchResource(data) {
            return this.type === "users" ? data.user_name : data.name;
        },
        removeResource(resource) {
            // this.$emit()
            this.$emit("removeAllocation", { resource, type: this.type, row: this.selectedRow });
        },
    },
    computed: {
        heading() {
            return `${this.type === "users" ? "Users" : "Groups"} assigned to Case`;
        },
        getAllocatedResources() {
            // return
            if (this.type === "users") {
                return this.allocatedResources.filter((res) => res.user_name.toLowerCase().includes(this.search.toLowerCase()));
            } else {
                return this.allocatedResources.filter((res) => res.name.toLowerCase().includes(this.search.toLowerCase()));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .work-modal {
    border-radius: 20px !important;
}
</style>
